        /*===========================
        Dom7 Library
        ===========================*/
        var Dom7 = (function () {
            var Dom7 = function (arr) {
                var _this = this, i = 0;
                // Create array-like object
                for (i = 0; i < arr.length; i++) {
                    _this[i] = arr[i];
                }
                _this.length = arr.length;
                // Return collection with methods
                return this;
            };
            var $ = function (selector, context) {
                var arr = [], i = 0;
                if (selector && !context) {
                    if (selector instanceof Dom7) {
                        return selector;
                    }
                }
                if (selector) {
                    // String
                    if (typeof selector === 'string') {
                        var els, tempParent, html;
                        selector = html = selector.trim();
                        if (html.indexOf('<') >= 0 && html.indexOf('>') >= 0) {
                            var toCreate = 'div';
                            if (html.indexOf('<li') === 0) toCreate = 'ul';
                            if (html.indexOf('<tr') === 0) toCreate = 'tbody';
                            if (html.indexOf('<td') === 0 || html.indexOf('<th') === 0) toCreate = 'tr';
                            if (html.indexOf('<tbody') === 0) toCreate = 'table';
                            if (html.indexOf('<option') === 0) toCreate = 'select';
                            tempParent = document.createElement(toCreate);
                            tempParent.innerHTML = html;
                            for (i = 0; i < tempParent.childNodes.length; i++) {
                                arr.push(tempParent.childNodes[i]);
                            }
                        }
                        else {
                            if (!context && selector[0] === '#' && !selector.match(/[ .<>:~]/)) {
                                // Pure ID selector
                                els = [document.getElementById(selector.split('#')[1])];
                            }
                            else {
                                // Other selectors
                                els = (context || document).querySelectorAll(selector);
                            }
                            for (i = 0; i < els.length; i++) {
                                if (els[i]) arr.push(els[i]);
                            }
                        }
                    }
                    // Node/element
                    else if (selector.nodeType || selector === window || selector === document) {
                        arr.push(selector);
                    }
                    //Array of elements or instance of Dom
                    else if (selector.length > 0 && selector[0].nodeType) {
                        for (i = 0; i < selector.length; i++) {
                            arr.push(selector[i]);
                        }
                    }
                }
                return new Dom7(arr);
            };
