            return $;
        })();
        
        // Export Dom7 to Framework7
        Framework7.$ = Dom7;
        
        // Export to local scope
        var $ = Dom7;
        
        // Export to Window
        window.Dom7 = Dom7;
        
